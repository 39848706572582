import { render, staticRenderFns } from "./CoreBlockFooterContact.vue?vue&type=template&id=62e14226&scoped=true"
import script from "./CoreBlockFooterContact.vue?vue&type=script&lang=js"
export * from "./CoreBlockFooterContact.vue?vue&type=script&lang=js"
import style0 from "./CoreBlockFooterContact.vue?vue&type=style&index=0&id=62e14226&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../Core.Generator/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62e14226",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreBlockFooterContactAddress: require('/usr/src/app/Core.Library/src/components/nyl/block/footer/contact/CoreBlockFooterContactAddress.vue').default,CoreBlockFooterContactPhone: require('/usr/src/app/Core.Library/src/components/nyl/block/footer/contact/CoreBlockFooterContactPhone.vue').default,CoreBlockFooterContactEmail: require('/usr/src/app/Core.Library/src/components/nyl/block/footer/contact/CoreBlockFooterContactEmail.vue').default})
