
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useStore, useRoot } from "@libraryHelpers/compositionApi";
import { useIsExpired } from "@libraryComposables/useIsExpired";

export default {
	name: "CoreBlockStickyButton",
	props: {
		isEditable: {
			type: Boolean,
			default: false,
		},
		isBuilder: {
			type: Boolean,
			default: false,
		},
		settings: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const root = useRoot();
		const { getters, state, commit } = useStore();
		const isDialogOpen = ref(false);
		const offset = ref(0);
		const isFixed = ref(false);
		const footerTop = ref(0);
		const mainNavHeight = ref(100);
		const isStickyNavOnTop = ref(false);

		const handleToggle = () => {
			if (
				props.isBuilder &&
				props.isEditable &&
				!state.interface.isLeftRailClosing
			) {
				const hasPermission = getters[
					"authorization/hasScopePermission"
				]("site", "SITE_ADVANCED_EDIT");
				if (!hasPermission) {
					return;
				}

				if (!state.interface.activeSidebarKey) {
					commit("interface/toggleActiveSidebarKey", "stickyButton");
				} else {
					commit("interface/setActiveSidebarKey");
				}

				commit(
					"interface/setRightRailOpening",
					!state.interface.isRightRailOpening
				);
				commit("site/clearSelectedBlockIds"); // Clears selected blocks without influencing the active sidebar key

				setTimeout(() => {
					const targetElement = document.querySelector(
						".editor-main-navigation"
					);
					if (targetElement)
						targetElement.scrollIntoView({ behavior: "smooth" });
				}, 500);
			}
		};

		const itemCheckFunction = (item) =>
			item.type === "document" || item.type === "link";

		const { isExpired } = useIsExpired(props.settings, itemCheckFunction);

		const buttonLabel = computed(() => {
			return props.settings?.label ?? "";
		});

		const buttonAltText = computed(() => {
			return props.settings?.alt || buttonLabel.value;
		});

		const buttonUrl = computed(() => {
			if (isExpired.value) return "";
			const url = props.settings?.url;
			if (
				typeof url === "string" &&
				url?.includes("https://calendly.com")
			) {
				return "";
			} else if (url === "dialog") {
				return "";
			}

			const newUrl = props.settings?.newWindow
				? replaceUrlBasePath(url)
				: url;
			return newUrl || "";
		});

		const clickEvent = computed(() => {
			if (
				typeof props.settings?.url === "string" &&
				props.settings?.url?.includes("https://calendly.com")
			) {
				return showPopUp;
			} else if (isDialog.value) {
				return toggleDialog;
			}
			return props.settings?.event || noEventFound;
		});

		const isInternal = computed(() => {
			const url = props.settings?.url;
			return (
				(typeof url === "string" && url?.charAt(0) == "/") ||
				(typeof url === "object" && url !== null)
			);
		});

		const isDialog = computed(() => {
			return props.settings?.url === "dialog";
		});

		const newWindowTarget = computed(() => {
			if (props.settings?.newWindow) return "_blank";
			return "_self";
		});

		const newWindowAriaLabel = computed(() => {
			if (props.settings.newWindow && buttonLabel.value)
				return `${buttonLabel.value} (opens in a new window)`;
			return null;
		});

		const buttonDataTestId = computed(() => {
			const calendlyUrl =
				typeof props.settings?.url === "string" &&
				props.settings?.url?.includes("https://calendly.com");
			return isDialog.value || calendlyUrl ? "dialog-button" : "button";
		});

		const formSettings = computed(() => {
			return props.settings?.dialog || {};
		});

		const noEventFound = () => {};

		const focusPopup = () => {
			// Calendly uses an iframe, it can't be manipulated because of
			// same-origin browser's policy.
			const container = document.querySelector(".calendly-popup-content");
			if (!container) {
				return;
			}
			const button = document.createElement("button");
			container.prepend(button);
			button.focus();
			button.remove();
		};

		const showPopUp = () => {
			GTMCalendly();
			if (process.client) {
				window.Calendly.showPopupWidget(props.settings?.url);
				setCalendlyAttributes(props.settings.iframeTitle);
				addModalListener();
				focusPopup();
			}
			return false;
		};

		const toggleDialog = () => {
			isDialogOpen.value = !isDialogOpen.value;
			return false;
		};

		const setCalendlyAttributes = (title) => {
			const iframes = document.querySelectorAll("iframe");
			for (const iframe of iframes) {
				if (iframe.src?.includes("https://calendly.com")) {
					iframe.setAttribute(
						"title",
						title || "default-calendly-title"
					);
				}
			}
			const calendlyCloseButton = document.querySelector(
				".calendly-popup-close"
			);
			calendlyCloseButton.setAttribute("tabIndex", "0");
			calendlyCloseButton.addEventListener(
				"keydown",
				closeModalEnterHandler,
				true
			);
		};

		const closeModalEnterHandler = (event) => {
			if (event.key === "Enter" || event.keyCode === 13) {
				closeCalendlyModal();
			}
		};

		const closeModalKeyHandler = (event) => {
			if (event.key === "Escape" || event.keyCode === 27) {
				closeCalendlyModal();
			}
		};

		const closeCalendlyModal = () => {
			window.Calendly.closePopupWidget();
			document.removeEventListener("keydown", closeModalKeyHandler, true);
		};

		const addModalListener = () => {
			document.addEventListener("keydown", closeModalKeyHandler, true);
		};

		const GTMCalendly = () => {
			const { eventType, calendlyId } = decodeCalendlyUrl(
				props.settings.url
			);
			root.$gtm.push({
				event: "nyl-calendly",
				calendly_id: calendlyId,
				calendly_category: getCalendlyCategory(eventType),
				event_type: eventType,
				calendly_event_id: eventType?.toLowerCase().replace(/\s/g, ""),
				click_url: props.settings.url,
			});
		};

		const getCalendlyCategory = (eventType) => {
			var calendlyCategory = "";
			switch (eventType) {
				case "Custom Event":
					calendlyCategory = "custom";
					break;
				case "General Discussion":
				case "Intro Appointment":
				case "Review":
					calendlyCategory = "agent";
					break;
				case "Intro to NYL":
				case "Quick Checkin":
				case "First Interview":
					calendlyCategory = "GO";
					break;
			}
			return calendlyCategory;
		};

		const replaceUrlBasePath = (url) => {
			if (!url || !isInternal.value) return url;

			const basePath = root?.$config?.["_app"]?.basePath ?? "/";
			return `${basePath}/${url}`.replace(/\/+/g, "/");
		};

		const decodeCalendlyUrl = (url) => {
			if (!url || typeof url !== "string" || url.trim() === "") {
				return {
					eventType: null,
					calendlyId: null,
				};
			}

			try {
				const urlObj = new URL(url);
				const pathParts = urlObj.pathname
					.split("/")
					.filter((part) => part !== "");
				const calendlyId = pathParts[0] || null;
				let eventType = "";

				if (pathParts.length > 1) {
					eventType = decodeURIComponent(pathParts[1]);
				}

				if (
					!eventType ||
					(eventType.includes("utm_campaign") &&
						eventType.includes("utm_source"))
				) {
					eventType = null;
				}

				return {
					eventType,
					calendlyId,
				};
			} catch (error) {
				console.error("Invalid Calendly URL:", error);
				return {
					eventType: null,
					calendlyId: null,
				};
			}
		};

		const isTop = computed(() => {
			return props.settings?.variants?.location?.includes("top");
		});

		const classList = computed(() => {
			const variants = props.settings?.variants || {};
			let classes = [];
			Object.keys(variants).forEach((key) => {
				if (variants[key]) {
					classes.push(`block_sticky-button_${variants[key]}`);

					if (
						variants[key] === "top-left" ||
						variants[key] === "bottom-left"
					) {
						classes.push(`block_sticky-button_pl-huge`);
						classes.push(`block_sticky-button_align-left`);
					}

					if (
						variants[key] === "top-right" ||
						variants[key] === "bottom-right"
					) {
						classes.push(`block_sticky-button_pr-huge`);
						classes.push(`block_sticky-button_align-right`);
					}
				}
			});
			if (isFixed.value) {
				classes.push(`block_sticky-button_fixed`);
			}
			return classes.join(" ");
		});

		const updateOffset = () => {
			if (props.isBuilder) {
				return;
			}

			if (isTop.value) {
				const top = document
					.getElementById("sticky-button")
					.getBoundingClientRect().top;

				offset.value = 0;

				isStickyNavOnTop.value = !!document?.querySelector(
					".block_anchor-link-nav_isTop .anchor_link_nav-fixed"
				);

				const TOP_OFFSET = isStickyNavOnTop.value ? 40 : 20;

				const mainNav = document.getElementById("main-nav");
				if (mainNav) {
					const navHeight =
						mainNav.getBoundingClientRect().height + TOP_OFFSET;
					offset.value = navHeight;
					if (top <= navHeight) {
						isFixed.value = true;
					}
				} else {
					if (top <= 0) {
						isFixed.value = true;
					} else {
						isFixed.value = false;
					}
				}
			} else {
				const BOTTOM_OFFSET = 30;
				const buttonHeight =
					document
						?.getElementById("sticky-button")
						?.querySelector("div")
						?.getBoundingClientRect().height ?? 0;

				const scrollPoint =
					window.scrollY + window.innerHeight - BOTTOM_OFFSET;

				const toggleFix = footerTop.value > scrollPoint;

				if (toggleFix) {
					offset.value = BOTTOM_OFFSET;
					isFixed.value = true;
				} else {
					offset.value = 0;
					isFixed.value = false;
				}
			}
		};

		onMounted(() => {
			window.onload = () => {
				footerTop.value =
					document
						?.getElementsByTagName("footer")?.[0]
						?.getBoundingClientRect().top ?? 0;

				mainNavHeight.value =
					document
						?.getElementById("main-nav")
						?.getBoundingClientRect().height ?? 100;
				updateOffset();
			};

			window.addEventListener("scroll", updateOffset);
		});

		onBeforeUnmount(() => {
			window.removeEventListener("scroll", updateOffset);
		});

		return {
			addModalListener,
			buttonAltText,
			buttonDataTestId,
			buttonLabel,
			buttonUrl,
			clickEvent,
			closeCalendlyModal,
			closeModalKeyHandler,
			formSettings,
			getCalendlyCategory,
			GTMCalendly,
			isDialog,
			isDialogOpen,
			isExpired,
			isInternal,
			newWindowAriaLabel,
			newWindowTarget,
			noEventFound,
			replaceUrlBasePath,
			setCalendlyAttributes,
			showPopUp,
			toggleDialog,
			handleToggle,
			classList,
			isTop,
			offset,
			isFixed,
			mainNavHeight,
		};
	},
};
